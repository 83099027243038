<template>
  <v-overlay
    v-bind="$attrs"
    opacity="1"
    :z-index="191991 * 1919191"
    :value="loader.show"
  >
    <div class="d-flex flex-column justify-center align-center">
      <v-img
        class="mb-2 "
        :src="require('@/assets/logo-center.png')"
        max-width="300px"
        style="margin-bottom:20px "
      ></v-img>
      <div style="text-align:center;position:relative;">
        <v-progress-circular
          class="mr-auto"
          indeterminate
          size="64"
        ></v-progress-circular>
      </div>
      <h1 class="headline loading_text" v-text="loader.text"></h1>
    </div>
  </v-overlay>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "loader",
  computed: {
    ...mapGetters({ loader: "loading" })
  }
};
</script>

<style scoped lang="scss">
.loading_text {
  //position: absolute;
  //left: 50%;
  //transform: translateX(-50%);
  width: 250px;
  margin: auto;
  text-align: center;
  &::after {
    content: " .";
    animation: dots 1s steps(5, end) infinite;
  }
  @keyframes dots {
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
      color: white;
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
      text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
      text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
    }
  }
}
</style>
